<template>
    <div>
        <div class="p-4" :class="windowWidth >= 576 ? 'flex justify-between items-end' : ''">
            <div :class="windowWidth >= 576 ? '' : 'mb-2'">
                <slot name="header-left"></slot>
            </div>
            <div>
                <slot name="header-right"></slot>
            </div>
        </div>
        <div :class="windowWidth >= 576 ? 'flex' : ''" :style="'min-height: ' + (heightStyle + 50) + 'px;'">
            <vs-sidebar class="cd-sidebar" :static-position="menuActive" :hidden-background="true" v-model="menuActive">
                <div class="h-full cd-filter">
                    <slot name="sidebar"></slot>
                </div>
            </vs-sidebar>
            <div class="p-4 bg-white w-full relative">
                <loadingSpinner :visible="isLoading" />
                <!-- <div :class="{ 'grid grid-cols-1 gap-6': gap, 'divide-y': divide }">
                    <slot name="items"></slot>
                </div> -->
                <div class="my-6 flex justify-end" v-if="false">
                    <slot name="btns"></slot>
                </div>
                <slot name="content"></slot>
                <div v-if="total == 0">
                    <noData>
                        <template #content>
                            <slot name="noData"></slot>
                        </template>
                    </noData>
                </div>
                <vs-pagination :max="windowWidth < 768 ? 5 : 7" :total="Math.ceil(total / limit)" v-model="page" v-if="total > limit"></vs-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import noData from './no_data.vue'
import loadingSpinner from '@/components/Common/loadingSpinner.vue'
export default {
    components: {
        noData,
        loadingSpinner,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
            require: true,
        },
        limit: {
            type: Number,
            default: 30,
            require: false,
        },
        total: {
            type: Number,
            default: 0,
            require: false,
        },
        gap: {
            type: Boolean,
            default: true,
            require: false,
        },
        divide: {
            type: Boolean,
            default: false,
            require: false,
        },
        menu: {
            type: Boolean,
            default: false,
            require: false,
        },
    },
    data() {
        return {
            page: 1,
        }
    },
    watch: {
        page(v) {
            this.$bus.$emit('changePage', this.page)
        },
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        heightStyle() {
            return window.innerHeight - 200
        },
        // 主選單開關
        menuActive: {
            get() {
                if (this.windowWidth > 1024) {
                    return true
                } else {
                    return this.menu
                }
            },
            set(val) {},
        },
    },
}
</script>
