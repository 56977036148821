<template>
    <div class="mb-6 flex items-center whitespace-no-wrap" v-if="data">
        <div class="w-64 flex flex-wrap overflow-hidden">
            <div class="mr-4">
                <span class="text-lg">{{ data.name }}</span>
            </div>
            <div class="space-x-4">
                <span>{{ $common.getGender(data.sex) }}</span>
                <span>{{ data.age }}</span>
            </div>
        </div>
        <div class="flex items-center space-x-4">
            <span>{{ data.date }}</span>
            <vs-button class="cd-button-2w rounded" icon-pack="feather" icon="icon-image" v-if="data.has_diagnosis == 0" @click="showPopup">判讀</vs-button>
            <div class="flex items-center text-primary cursor-pointer hover:font-semibold" v-else>
                <feather-icon icon="ImageIcon" svgClasses="h-4 w-4" class="mr-1" />
                <span class="text-lg" @click="showPopup">{{ data.health_data.value_map[data.health_data.value] }}</span>
                <!-- <span class="text-primary cursor-pointer hover:font-semibold" @click="showPopup">檢視</span> -->
            </div>
        </div>
        <vs-popup fullscreen title="肺結核(TB)判讀" :active.sync="popupActive" @close="closePopup">
            <diagnosisForm :source_url="data.source_url">
                <template slot="form">
                    <div class="mb-6 text-xl flex justify-center space-x-4">
                        <vs-radio v-model="diagnosis" vs-name="diagnosis" vs-value="0">無法判斷</vs-radio>
                        <vs-radio v-model="diagnosis" vs-name="diagnosis" vs-value="1">正常</vs-radio>
                        <vs-radio v-model="diagnosis" vs-name="diagnosis" vs-value="2">異常</vs-radio>
                    </div>
                </template>
                <template slot="btns">
                    <vs-button class="cd-buttom-2w rounded" @click="diagnosisTb">確定</vs-button>
                    <vs-button class="cd-buttom-2w rounded" type="border" @click="closePopup">取消</vs-button>
                </template>
                <template slot="file">
                    <img :src="img" :width="imgWidth + 'px'" />
                </template>
            </diagnosisForm>
        </vs-popup>
    </div>
</template>
<script>
import { diagnosisTb } from '@/api/user'
import diagnosisForm from '@/components/Module/diagnosisForm'
export default {
    components: {
        diagnosisForm,
    },
    props: {
        data: {
            type: Object,
            required: true,
            default: null,
        },
    },
    data() {
        return {
            popupActive: false,
            diagnosis: null,
        }
    },
    computed: {
        img() {
            return process.env.VUE_APP_UPLOAD + '/ebm/' + this.data.imagefile
        },
        partner_id() {
            return this.$store.state.partner
        },
        imgWidth() {
            const innerWidth = window.innerWidth
            const width = innerWidth > 630 ? innerWidth - 100 : innerWidth - 100
            return width
        },
    },
    methods: {
        showPopup() {
            this.popupActive = true
        },
        closePopup() {
            this.popupActive = false
        },
        diagnosisTb() {
            if (this.data.has_diagnosis == null) return
            const payload = {
                doctorcare_id: this.data.id,
                value: this.diagnosis,
                partner_id: this.partner_id,
            }
            diagnosisTb(payload)
                .then((res) => {
                    const mtypeList = res.data.data.mtypeList
                    this.data.has_diagnosis = 1
                    this.data.health_data = {}
                    this.data.health_data.value = this.diagnosis
                    this.data.health_data.value_map = mtypeList.value_map
                })
                .finally(() => {
                    this.closePopup()
                })
        },
    },
}
</script>
