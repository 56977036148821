<template>
    <div class="grid grid-cols-1 gap-8 place-items-center">
        <div class="w-full py-12 bg-grey-light">
            <slot name="form"></slot>
            <div class="flex justify-center space-x-4">
                <slot name="btns"></slot>
            </div>
        </div>
        <div>
            <div class="flex justify-center text-primary cursor-pointer hover:font-semibold" v-if="source_url">
                <feather-icon class="mr-1" icon="LinkIcon" svgClasses="h-4 w-4"></feather-icon>
                <span @click="$common.openExternalUrl(source_url)">檢視原始資料</span>
            </div>
            <slot name="file"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        source_url: {
            type: String,
            required: false,
            default: '',
        },
    },
}
</script>
