<template>
    <div class="cd-body">
        <list :menu="menu" :total="total" :limit="limit" :isLoading="isLoading">
            <template v-slot:header-left>
                <span>肺結核(TB)判讀</span>
            </template>
            <template v-slot:header-right>
                <!-- 篩選 -->
                <div v-if="windowWidth <= 1024">
                    <vs-button class="cd-button-2w-icon rounded" type="flat" icon-pack="feather" icon="icon-filter" @click="switchFilter">{{ $t('apptMgmt.filter') }}</vs-button>
                </div>
            </template>
            <template v-slot:sidebar>
                <div class="mb-8">
                    <vs-input icon-no-border clearable icon="icon-search" icon-pack="feather" class="w-full input-rounded-full" v-model="query" />
                </div>
                <div class="mb-8 grid grid-cols-1 gap-4 whitespace-no-wrap">
                    <vs-radio v-model="readStatus" vs-name="readStatus" vs-value="0">未判讀</vs-radio>
                    <vs-radio v-model="readStatus" vs-name="readStatus" vs-value="1">已判讀</vs-radio>
                    <vs-radio v-model="readStatus" vs-name="readStatus" vs-value="2">全部</vs-radio>
                </div>
            </template>
            <template v-slot:content>
                <div class="p-8">
                    <item v-for="(item, index) in data" :key="index" :data="item"></item>
                </div>
            </template>
        </list>
    </div>
</template>
<script>
import { getTbFiles } from '@/api/user'
import list from '@/components/Module/list.vue'
import item from '@/components/Tb/TbItem.vue'
import { mapState } from 'vuex'
import { debounce } from 'lodash'
export default {
    components: { list, item },
    data() {
        return {
            menu: false,
            data: [],
            total: 0,
            limit: 50,
            page: 1,
            isLoading: false,
            readStatus: 0,
            query: '',
        }
    },
    mounted() {
        this.getData()
        this.createBus()
    },
    watch: {
        query() {
            this.getData()
        },
        readStatus() {
            this.getData()
        },
    },
    computed: {
        ...mapState(['windowWidth']),
    },
    methods: {
        getData: debounce(function () {
            this.isLoading = true
            const payload = {
                query: this.query,
                limit: this.limit,
                page: this.page,
            }
            if (this.readStatus == 0) payload['hasDiagnosis'] = 0
            if (this.readStatus == 1) payload['hasDiagnosis'] = 1
            getTbFiles(payload)
                .then((res) => {
                    this.data = res.data.data.data
                    this.total = res.data.data.total
                })
                .finally(() => {
                    this.isLoading = false
                })
        }, 200),
        // 開關篩選sidebar (手機版)
        switchFilter() {
            this.menu = !this.menu
        },
        createBus() {
            this.$bus.$on('changePage', (page) => {
                this.page = page
                this.getData()
            })
        },
    },
    beforeDestroy() {
        this.$bus.$off('changePage')
    },
}
</script>
